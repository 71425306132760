.swa3d-teaser-container {
  grid-row-start: 1;
  grid-column-start: 1;
  z-index: 10;

  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f0eee4;
  transition: opacity 2s ease;

  &--hide {
    opacity: 0;
    pointer-events: none;
  }

  &-header {
    margin: 16px;
    text-align: center;
    font-size: 32px;
  }

  &-teaser {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 16px;
    text-align: center;
  }
}

@media only screen and (min-width: 1024px) {
  .swa3d-teaser-container {
    justify-content: center;

    &-header {
      margin: 0rem 8rem 8rem 8rem;
      font-size: 48px;
    }

    &-teaser {
      flex-direction: row;
    }
  }

  @media (max-height: 1024px) {
    .swa3d-teaser-container {
      &-header {
        margin: 0rem 4rem 4rem 4rem;
      }
    }
  }
}
