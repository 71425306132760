.swa3d-card {
  position: relative;
  padding-top: 66.66%;
  width: 100%;
  height: 100%;
  font-size: 1.5vw;
  z-index: 1;

  @media screen and (min-width: 1024px){
    font-size: 1vw;
  }

  @media screen and (min-width: 1280px){
    font-size: min(0.75vw, 14px);
  }


  &-hide {
    display: none;
    opacity: 0;
  }

  &-show {
    animation-name: fadein;
    animation-duration: 1s;
    animation-timing-function: ease-out;
  }

  &-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 1.3s;
    transform-style: preserve-3d;
    cursor: pointer;

    // Position div children of .swa3d-card-inner so that they stack
    & > div {
      position: absolute;
      top: 0;
      left: 0;
      height: inherit;
      width: inherit;
      border-radius: 2vw;
      box-shadow: 0rem 0rem 2rem -1rem #000;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }
  }

  &-image {
    width: 100%;
    height: 100%;
    border-radius: 2vw;
  }

  &-text {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 5% 5% 9% 5%;
    text-align: center;
    overflow-wrap: break-word;

    // The idea here is that the text is always relative to the size of the flipcard,
    // therefore a percentage value is used here.
    // The container just need to set the font-size to it's width.

    &--headline {
      font-size: 3em !important;
      font-weight: normal !important;
      line-height: initial !important;
      margin: 0 !important;

      &-placeholder {
        color: #acacac !important;
      }

      &-two-lines {
        font-size: 1.75em !important;
      }
    }

    &--message {
      overflow-y: hidden;
      white-space: pre-line;
      font-size: 1.5em !important;

      &-long {
        font-size: 1.25em !important;
      }
    }

    &--footer {
      font-size: 1.5em !important;
      line-height: initial !important;
      align-self: flex-start;
      margin: 0 auto !important;

      &-long {
        font-size: 1.25em !important;
      }
    }
  }

  &-flip &-inner {
    transform: rotateY(180deg);
  }

  &-front {
    color: black;

    .swa3d-card-image {
      // background-image: linear-gradient(to bottom right, #301441, #231057);
      background-image: linear-gradient(to bottom right, #f2f2f2, #e6e6e6);
    }

    img {
      width: 15%;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      filter: brightness(0.98) drop-shadow(0px 0px 2px lightgray);
    }
  }

  &-back {
    transform: rotateY(180deg);

    .swa3d-card-image {
      background-image: linear-gradient(to bottom right, #f2f2f2, #e6e6e6);
      border: 1.5px solid #fafafa;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }

    img {
      width: 25%;
      height: auto;
      margin-right: 3%;
      margin-bottom: 3%;
      filter: brightness(0.2);
    }
  }
}

@media only screen and (min-width: 768px) {
  .swa3d-card {
    &-inner {
      // Position div children of .swa3d-card-inner so that they stack
      & > div {
        border-radius: 15px;
      }
    }

    &-image {
      border-radius: 15px;
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
