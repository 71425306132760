.swa3d-slider {
  position: relative;
  overflow: hidden;

  &__container {
    display: flex;
    overflow: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    //Hides scrollbar
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }

    &--swipe-disabled {
      scroll-behavior: auto;
      overflow: hidden;
    }
  }

  &__slide {
    position: relative;
    flex: 1 0 100%;
    scroll-snap-align: start;
  }

  &--dark {
    .swa3d-slider__btn {
      filter: brightness(25);
    }
  }

  &__btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    padding: 1rem 1.5rem;
    cursor: pointer;
    user-select: none;

    &--next {
      right: 0;
    }

    &--prev {
      left: 0;
    }

    img {
      transition: transform .25s ease;
    }

    @media (hover: hover) {
      &:hover {
        img {
          transform: scale(0.8);
        }
      }
    }
  }
}