.swa3d-thumbnails {
  padding-top: 6px;
  margin: -6px 0;

  @media screen and (min-width: 768px){
    padding-top: 12px;
    margin: -12px 0;
  }

  @media screen and (min-width: 1024px){
    margin: -12px;
  }

  &__container {
    display: grid;
    grid-template-columns: minmax(44px, 1fr) minmax(44px, 1fr) minmax(44px, 1fr) minmax(44px, 1fr) minmax(44px, 1fr) minmax(44px, 1fr);
    grid-gap: 6px;
    padding: 6px;
    overflow: auto;

    @media screen and (min-width: 768px) {
      grid-gap: 12px;
      padding: 12px;
    }
  }

  &__thumb {
    position: relative;
    cursor: pointer;
    padding-top: 66%;
    opacity: 0.7;
    transition: opacity .25s linear;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after, &::before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    &::after {
      left: 0;
      box-sizing: border-box;
      border: 2px solid black;
      transition: border .25s linear, opacity .25s linear, background-color .25s linear;
    }

    &::before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 270 270' width='10' height='10'%3E%3Cpath d='M 30,180 90,240 240,30' style='stroke:%23fff; stroke-width:30; fill:none' /%3E%3C/svg%3E");
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      background-color: black;
      border-radius: 50%;
      right: 0;
      transform: translate(33%, -33%);
      transition: opacity .25s linear;

      @media screen and (max-width: 767px){
        transform: translate(33%, -33%) scale(0.8);
      }
    }

    @media (hover: hover) {
      &:hover {
        opacity: 1;
      }
    }

    &--active {
      opacity: 1;

      &::after {
        opacity: 1;
      }

      &::before {
        opacity: 1;
      }
    }
  }
}