#js-swa3d-model-background--error {
  width: 90%;
  height: auto;
  top: 50%;
  transform: translate(-50%, -50%);
}

.swa3d-model-background {
  &-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 1;
    transition: opacity 0.2s ease-out;
  }

  position: absolute;
  top: 0;
  left: 50%;
  width: auto;
  height: 100%;
  opacity: 0;
  transform: translateX(-50%);

  &--show {
    opacity: 1;
    z-index: -2;
  }

  &--transition {
    transition: opacity 0.5s ease-out;
  }
}
