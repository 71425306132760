.swa3d-indicator-bar{
    display: flex;
    flex-direction: row;
    justify-content: center;

    &-child{
        pointer-events: none;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        border: 1px solid black;
        margin-right: 8px;
        opacity: 0.9;
        // box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
        transition: background-color 0.2s ease-in-out;
        &:last-child{
            margin-right: 0;
        }
        &--active{
            border: 1px solid black;
            background-color: black;
        }
    }
}
