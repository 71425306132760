.swa3d-timeline {
  position: relative;
  display: none;

  hr {
    width: 100%;
    border: 0px solid black;
    height: 1px;
    background: black;
  }

  &-item {
    .swa3d-timeline-item-active {
      background-color: black;
      color: white;
    }

    &--stepName {
      font-size: 1.4rem;
      line-height: 2rem;
      text-align: center;
      transition: font-weight .25s linear;

      &--active {
        font-weight: bold;
      }
    }

    &--step {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      font-size: 1.6rem;
      border: 2px solid;
      background-color: white;
      transition: color .25s linear, background-color .25s linear, border-color .25s linear
    }

    &--active {
      background-color: black;
      border-color: black;
      color: white;
    }
  }

  &-mobile {
    padding: 16px;
    left: 0;
    font-size: 12px;
    color: #757982;
  }

  &-row {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    &-bottom {
      margin-top: 8px;
      align-items: flex-start;
    }

    &--name {
      color: black;
      margin-top: 4px;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .swa3d-timeline {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100vw;
    align-items: center;
    justify-content: center;
    max-width: 468px;

    &-mobile {
      display: none;
    }

    &-item {
      &-text {     
        width: 95px;
      }

      &-text:first-of-type {
          // size of number box is 40px, the text box is 90px wide
          // thats why this element needs to be moved 25px
          // 90px - 40px = 50px, 50px / 2 = 25px
          transform: translateX(-27.5px);
      }

      &-text:last-of-type {
        transform: translateX(25px);
    }
    }
  }
}
