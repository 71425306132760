:root {
  --app-height: 100%;
  --opacityLayer: 0.4;
}

.swa3d-gift {
  position: relative;
  width: 100vw;
  min-height: calc(var(--app-height) - 53px);
  grid-row-start: 1;
  grid-column-start: 1;

  &:before {
    transition: opacity 0s linear 0.5s;
  }

  &:after {
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
  }

  &--hide {
    display: none;
  }

  &-video-player {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
    opacity: 1;
    transition: opacity 1s ease;

    &--hide {
      opacity: 0;
      pointer-events: none;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: inherit;
    justify-content: space-between;
    align-items: center;
    overflow-x: hidden;

    &-main {
      position: relative;
      width: 100%;
      // min-height: 100%;
      flex-grow: 1;
      display: flex;
    }
  }

  &-model {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 256px;
    flex-grow: 1;
    transition: opacity 1s ease;

    &--hide {
      opacity: 0;
    }
  }

  & model-viewer {
    width: 100%;
    // height: 100%;
    flex-grow: 1;
    background-color: transparent;
    --poster-color: transparent;

    &::part(default-progress-mask) {
      display: none;
    }
  }

  &-card {
    position: fixed;
    width: 80vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    z-index: 1;
    pointer-events: none;

    &--show {
      animation: cardMoveIn 1.5s ease;
    }

    &--hide {
      animation: cardMoveOut 1.5s ease;
    }

    .swa3d-card {

      @media screen and (min-width: 768px){
        font-size: 2vw;
      }

      @media screen and (min-width: 1024px){
        font-size: min(1.3vw, 20px);
      }
    }
  }

  &-cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    z-index: 1;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    pointer-events: none;

    &--show {
      opacity: var(--opacityLayer);
    }

    &--interaction {
      cursor: pointer;
      pointer-events: auto;
    }
  }

  &-sections {
    width: 100vw;
    display: grid;

    &--hide {
      opacity: 0;
      pointer-events: none;
    }
  }

  &-section {
    grid-row-start: 1;
    grid-column-start: 1;
    align-self: end;
    transition: opacity 0.5s ease-out;
    padding: 16px;

    &--hide {
      opacity: 0;
      pointer-events: none;
    }

    &-buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;

      button {
        min-width: 240px;
        /*max-width: 344px;*/
      }
    }
  }
}

.swa3d-gift .swa3d-gift:before,
.swa3d-gift:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
}

/* @media only screen and (min-width: 768px) {

} */

@media only screen and (min-width: 1024px) {
  .swa3d-gift {
    min-height: calc(100vh - 60px);
    display: flex;
    justify-content: center;

    &-video-player {
      width: 80%;
    }

    &-content {
      flex-direction: row;
      justify-content: center;
      width: 80%;
      margin: 0 auto;
      overflow: visible;

      &-main {
        max-width: 50vw;
        min-height: inherit;
      }
    }

    &-model {
      min-height: inherit;
    }

    &-card {
      width: 50vw;
      max-width: 768px;

      // fallback for min()
      font-size: 38vw;

      // The idea is to always have font size equal to width
      font-size: min(50vw, 768px);

      height: 33vw;
      max-height: 512px;
    }

    &-spacer-desktop {
      width: 5vw;
      transition: width 1s ease;

      &--hide {
        width: 0;
      }
    }

    &-sections {
      width: 344px;
      transition: width 1s ease;

      &--hide {
        width: 0;
      }
    }

    &-section {
      min-width: 344px;
      align-self: center;
      padding: 0;
    }
  }
}

/* @media only screen and (min-width: 1440px) {

} */

@keyframes cardMoveIn {
  0% {
    top: -25%;
  }
  100% {
    top: 50%;
  }
}
@keyframes cardMoveOut {
  0% {
    top: 50%;
  }
  100% {
    top: -50%;
    display: none;
  }
}
