:root {
  --app-height: 100%;
  --content-height: unset;
}

.swa3d-container {
  display: grid;
}

.swa3d-configurator-container {
  position: relative;
  display: grid;
  align-items: stretch;
  min-height: calc(100vh - 53px); // Mobile header is 53px high
  width: 100%;


  @media screen and (min-width: 768px) {
    padding-top: 24px;
  }

  @media screen and (min-width: 1024px) {
    align-items: center;
    padding: 48px 24px;
    min-height: calc(100vh - 60px);
    margin: 0 auto;

    @media screen and (min-width: 1280px) {
      width: 80vw;
    }
  }
}

.swa3dform-to-from-container {
  display: flex;

  :first-child {
    margin-right: 10px;
  }

  & > div {
    flex-grow: 1;
  }
}

.swa3d-configurator {
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-row-start: 1;
  grid-column-start: 1;
  transition: opacity 1.5s ease;

  @media screen and (min-width: 1024px) {
    grid-template-columns: calc(60% - 12px) calc(40% - 12px);
    grid-auto-rows: auto auto;
    grid-column-gap: 24px;
    grid-row-gap: min(5%, 24px);
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
  }

  &--hide {
    opacity: 0;
    pointer-events: none;
  }

  &--lock {
    pointer-events: none;
  }

  &:before {
    transition: opacity 0s linear 0.5s;
  }

  &:after {
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
  }

  &-timeline {
    position: relative;
    z-index: 1;
    grid-column: 1 / 3;
    grid-row: 1 / 2;
  }

  &-main-content {
    position: relative;
    display: grid;
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    align-items: center;
    //
    //&--fixed-height {
    //  height: var(--content-height);
    //  max-height: var(--content-height);
    //}

    @media screen and (min-width: 1024px) {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
    }
  }

  &-video {
    opacity: 1;
    transition: opacity 0.5s ease;

    &-container {
      position: relative;
      grid-area: 1 / 1 / 1 / 1;
    }

    &--hide {
      opacity: 0;
      pointer-events: none;
    }
  }

  &-model {
    grid-area: 1 / 1 / 1 / 1;
    position: relative;
    padding-top: 80%;
    opacity: 0;
    pointer-events: none;
    will-change: transform;
    transform: scale(1.1);
    transition: opacity 0.5s ease, transform 0.5s ease;
    z-index: -1;

    &--show {
      z-index: unset;
      opacity: 1;
      pointer-events: all;
      transform: scale(1);
    }

    &-indicator {
      display: none;

      &-hidden {
        display: none;
      }
    }

    &-indicator-mobile {
      margin-top: 16px;
    }
  }

  model-viewer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    --poster-color: transparent;
  }

  &-arrows {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--white {
      filter: brightness(25);
    }
  }

  &-arrow {

    &:active {
      cursor: pointer;

      img {
        transform: scale(0.85);
        opacity: 0.75;
      }
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        cursor: pointer;

        img {
          transform: scale(0.85);
          opacity: 0.75;
        }
      }
    }

    &--left {
      margin-left: 16px;
    }

    &--right {
      margin-right: 16px;
    }
  }

  &-flipcard {
    position: absolute;
    bottom: 30%;
    right: 50%;
    transform: translateX(66%);
    width: 55%;
    max-width: 460px;
    pointer-events: none;
    cursor: auto;
    z-index: 1;
    transition: bottom 2s 1s ease;

    &--hide {
      bottom: 150vh;
    }

    .swa3d-video {
      overflow: hidden;
      border-radius: 8px;
      transform: translate(-33.3%, 66.6%);
    }

    .swa3d-card {
      z-index: 1;
    }
  }

  &-spacer-mobile {
    flex-grow: 1;
  }

  &-text-column {
    display: flex;
    align-items: center;
    grid-row: 3/4;
    grid-column: 1/3;
    padding: 16px;
    z-index: 5;

    @media screen and (min-width: 1024px) {
      grid-row: 2/3;
      grid-column: 2/3;
      padding: 0;
    }
  }

  &-info-text {
    font-size: 1.6rem !important;
    line-height: 2.5rem !important;
    margin-bottom: 8px;
  }
}

@media only screen and (min-width: 1024px) {
  .swa3d-configurator {
    &-model {
      &-indicator {
        display: unset;
        position: absolute;
        bottom: 10%;
        width: 100%;

        &-hidden {
          display: unset;
          opacity: 0;
          pointer-events: none;
        }
      }

      &-indicator-mobile {
        display: none;
      }
    }

    &-headline-step3 {
      font-size: 2.6rem !important;
    }
  }

  @media (min-height: 897px) {
    .swa3dform-to-from-container {
      display: unset;

      :first-child {
        margin-right: 0;
      }
    }
  }
}

.swa3d-configurator .swa3d-configurator:before,
.swa3d-configurator:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
}
