.swa3d-video {
  position: relative;
  opacity: 1;
  transition: opacity 0.2s ease;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    opacity: 0;
    z-index: -1;

    &[controls] {
      opacity: 1;
      z-index: 0;
    }
  }

  &-poster {
    position: relative;
    width: 100%;
    height: auto;
    border: none;
    transition: opacity .25s linear;
  }

  &-button__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity .25s linear;
  }

  &-button {
    cursor: pointer;
    box-sizing: content-box;
    width: 25%;
    max-width: 96px;
    padding: 1rem;
    height: auto;
    opacity: 0.67;

    img {
      width: 100%;
      height: auto;
    }

    @media (hover: hover) {
      &:hover + .swa3d-video-button__bg{
          opacity: 0.2;
      }
    }
  }

  &-button__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    pointer-events: none;
    opacity: 0;
    z-index: 1;
    transition: opacity .25s linear;
  }

  .hideOut {
    opacity: 0;
    pointer-events: none;
  }
}