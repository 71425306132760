.swa3d-teaser {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 344px;
  background-color: white;
  margin-bottom: 16px;
  transition: box-shadow 0.2s ease;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    box-shadow: 0 2px 20px 0px rgb(0 0 0 / 20%);
  }

  &-image-container {
    width: 100%;
    padding-top: 100%;
    position: relative;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &-content {
    padding: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    & > div {
      flex-grow: 1;
      height: 100%;
    }

    &-placeholder {
      min-height: 46px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .swa3d-teaser {
    max-width: 400px;
  }
}

@media only screen and (min-width: 1024px) {
  .swa3d-teaser {
    margin-right: 24px;
    margin-bottom: 0;

    &:last-child {
      margin-right: 0;
    }

    @media (max-height: 1024px) {
      &-image-container {
        padding-top: 75%;
      }
    }
  }
}
